export default {
  "00e63d3e": [() => import(/* webpackChunkName: "00e63d3e" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-guide-07f.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-guide-07f.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-guide-07f.json")],
  "00ee7c5d": [() => import(/* webpackChunkName: "00ee7c5d" */ "@site/src/pages/dashboard.tsx"), "@site/src/pages/dashboard.tsx", require.resolveWeak("@site/src/pages/dashboard.tsx")],
  "067cd1c7": [() => import(/* webpackChunkName: "067cd1c7" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-getting-started-2e7.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-getting-started-2e7.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-getting-started-2e7.json")],
  "0d82baa1": [() => import(/* webpackChunkName: "0d82baa1" */ "@site/docs/guides/oauth2/index.md"), "@site/docs/guides/oauth2/index.md", require.resolveWeak("@site/docs/guides/oauth2/index.md")],
  "0e384e19": [() => import(/* webpackChunkName: "0e384e19" */ "@site/docs/intro.md"), "@site/docs/intro.md", require.resolveWeak("@site/docs/intro.md")],
  "0f2dc7a8": [() => import(/* webpackChunkName: "0f2dc7a8" */ "@site/docs/guides/about-the-deriv-api/index.md"), "@site/docs/guides/about-the-deriv-api/index.md", require.resolveWeak("@site/docs/guides/about-the-deriv-api/index.md")],
  "106bb502": [() => import(/* webpackChunkName: "106bb502" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-application-f80.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-application-f80.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-application-f80.json")],
  "138e0e15": [() => import(/* webpackChunkName: "138e0e15" */ "@generated/@easyops-cn/docusaurus-search-local/default/__plugin.json"), "@generated/@easyops-cn/docusaurus-search-local/default/__plugin.json", require.resolveWeak("@generated/@easyops-cn/docusaurus-search-local/default/__plugin.json")],
  "13f0bf92": [() => import(/* webpackChunkName: "13f0bf92" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-integration-768.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-integration-768.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-integration-768.json")],
  "14eb3368": [() => import(/* webpackChunkName: "14eb3368" */ "@theme/DocCategoryGeneratedIndexPage"), "@theme/DocCategoryGeneratedIndexPage", require.resolveWeak("@theme/DocCategoryGeneratedIndexPage")],
  "151311f1": [() => import(/* webpackChunkName: "151311f1" */ "@site/docs/languages/javascript/project-setup/index.md"), "@site/docs/languages/javascript/project-setup/index.md", require.resolveWeak("@site/docs/languages/javascript/project-setup/index.md")],
  "17896441": [() => import(/* webpackChunkName: "17896441" */ "@theme/DocItem"), "@theme/DocItem", require.resolveWeak("@theme/DocItem")],
  "1a4e3797": [() => import(/* webpackChunkName: "1a4e3797" */ "@theme/SearchPage"), "@theme/SearchPage", require.resolveWeak("@theme/SearchPage")],
  "1d5ddc79": [() => import(/* webpackChunkName: "1d5ddc79" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-status-4f6.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-status-4f6.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-status-4f6.json")],
  "1df93b7f": [() => import(/* webpackChunkName: "1df93b7f" */ "@site/src/pages/index.tsx"), "@site/src/pages/index.tsx", require.resolveWeak("@site/src/pages/index.tsx")],
  "20a80264": [() => import(/* webpackChunkName: "20a80264" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-copy-trading-f07.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-copy-trading-f07.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-copy-trading-f07.json")],
  "226292a3": [() => import(/* webpackChunkName: "226292a3" */ "@site/docs/languages/javascript/get-country-list/index.md"), "@site/docs/languages/javascript/get-country-list/index.md", require.resolveWeak("@site/docs/languages/javascript/get-country-list/index.md")],
  "22f905b1": [() => import(/* webpackChunkName: "22f905b1" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-monetization-f2b.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-monetization-f2b.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-monetization-f2b.json")],
  "25c9e0bc": [() => import(/* webpackChunkName: "25c9e0bc" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-history-cec.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-history-cec.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-history-cec.json")],
  "26974b24": [() => import(/* webpackChunkName: "26974b24" */ "@site/docs/languages/javascript/websocket-connection/index.md"), "@site/docs/languages/javascript/websocket-connection/index.md", require.resolveWeak("@site/docs/languages/javascript/websocket-connection/index.md")],
  "2720e7ca": [() => import(/* webpackChunkName: "2720e7ca" */ "@site/docs/guides/monetizing-the-deriv-api/index.md"), "@site/docs/guides/monetizing-the-deriv-api/index.md", require.resolveWeak("@site/docs/guides/monetizing-the-deriv-api/index.md")],
  "363900ef": [() => import(/* webpackChunkName: "363900ef" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-create-770.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-create-770.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-create-770.json")],
  "3720c009": [() => import(/* webpackChunkName: "3720c009" */ "@theme/DocTagsListPage"), "@theme/DocTagsListPage", require.resolveWeak("@theme/DocTagsListPage")],
  "38d6aa8d": [() => import(/* webpackChunkName: "38d6aa8d" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-proposal-0a6.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-proposal-0a6.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-proposal-0a6.json")],
  "3b4519f2": [() => import(/* webpackChunkName: "3b4519f2" */ "@site/src/pages/endpoint.tsx"), "@site/src/pages/endpoint.tsx", require.resolveWeak("@site/src/pages/endpoint.tsx")],
  "3b966a79": [() => import(/* webpackChunkName: "3b966a79" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-tutorial-44f.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-tutorial-44f.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-tutorial-44f.json")],
  "3c0347de": [() => import(/* webpackChunkName: "3c0347de" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-trading-b3a.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-trading-b3a.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-trading-b3a.json")],
  "4072399a": [() => import(/* webpackChunkName: "4072399a" */ "@site/docs/languages/intro.md"), "@site/docs/languages/intro.md", require.resolveWeak("@site/docs/languages/intro.md")],
  "43d486c1": [() => import(/* webpackChunkName: "43d486c1" */ "@site/src/pages/auth.tsx"), "@site/src/pages/auth.tsx", require.resolveWeak("@site/src/pages/auth.tsx")],
  "471203e7": [() => import(/* webpackChunkName: "471203e7" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-setup-0ac.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-setup-0ac.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-setup-0ac.json")],
  "5b3511e6": [() => import(/* webpackChunkName: "5b3511e6" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-calls-90e.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-calls-90e.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-calls-90e.json")],
  "5bfee675": [() => import(/* webpackChunkName: "5bfee675" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-aab.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-aab.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-aab.json")],
  "5cd19322": [() => import(/* webpackChunkName: "5cd19322" */ "@site/docs/core-concepts/api-calls-anatomy/index.md"), "@site/docs/core-concepts/api-calls-anatomy/index.md", require.resolveWeak("@site/docs/core-concepts/api-calls-anatomy/index.md")],
  "5e95c892": [() => import(/* webpackChunkName: "5e95c892" */ "@theme/DocsRoot"), "@theme/DocsRoot", require.resolveWeak("@theme/DocsRoot")],
  "5e9f5e1a": [() => import(/* webpackChunkName: "5e9f5e1a" */ "@generated/docusaurus.config"), "@generated/docusaurus.config", require.resolveWeak("@generated/docusaurus.config")],
  "5f4a90c0": [() => import(/* webpackChunkName: "5f4a90c0" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-quick-start-93d.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-quick-start-93d.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-quick-start-93d.json")],
  "63396786": [() => import(/* webpackChunkName: "63396786" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-monetize-216.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-monetize-216.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-monetize-216.json")],
  "67f10a19": [() => import(/* webpackChunkName: "67f10a19" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-website-88d.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-website-88d.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-website-88d.json")],
  "6f15d0a6": [() => import(/* webpackChunkName: "6f15d0a6" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-contracts-f7a.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-contracts-f7a.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-contracts-f7a.json")],
  "70a6aedb": [() => import(/* webpackChunkName: "70a6aedb" */ "@site/docs/setting-up-a-deriv-application.md"), "@site/docs/setting-up-a-deriv-application.md", require.resolveWeak("@site/docs/setting-up-a-deriv-application.md")],
  "77deb5cb": [() => import(/* webpackChunkName: "77deb5cb" */ "@site/docs/code-examples/javascript/contracts-for-symbol/index.mdx"), "@site/docs/code-examples/javascript/contracts-for-symbol/index.mdx", require.resolveWeak("@site/docs/code-examples/javascript/contracts-for-symbol/index.mdx")],
  "78a9ed3e": [() => import(/* webpackChunkName: "78a9ed3e" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-markup-6c0.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-markup-6c0.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-markup-6c0.json")],
  "7ba4fed8": [() => import(/* webpackChunkName: "7ba4fed8" */ "@site/docs/code-examples/javascript/ticks-history/index.mdx"), "@site/docs/code-examples/javascript/ticks-history/index.mdx", require.resolveWeak("@site/docs/code-examples/javascript/ticks-history/index.mdx")],
  "7c110bd0": [() => import(/* webpackChunkName: "7c110bd0" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-b5f.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-b5f.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-b5f.json")],
  "81b1644f": [() => import(/* webpackChunkName: "81b1644f" */ "@site/docs/code-examples/javascript/website-status/index.mdx"), "@site/docs/code-examples/javascript/website-status/index.mdx", require.resolveWeak("@site/docs/code-examples/javascript/website-status/index.mdx")],
  "81b5ab99": [() => import(/* webpackChunkName: "81b5ab99" */ "@site/docs/code-examples/javascript/keep-alive/index.mdx"), "@site/docs/code-examples/javascript/keep-alive/index.mdx", require.resolveWeak("@site/docs/code-examples/javascript/keep-alive/index.mdx")],
  "83548880": [() => import(/* webpackChunkName: "83548880" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-keep-2a3.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-keep-2a3.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-keep-2a3.json")],
  "8517a376": [() => import(/* webpackChunkName: "8517a376" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-earn-e17.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-earn-e17.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-earn-e17.json")],
  "8a4f5466": [() => import(/* webpackChunkName: "8a4f5466" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-active-5fc.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-active-5fc.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-active-5fc.json")],
  "8db5eb52": [() => import(/* webpackChunkName: "8db5eb52" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-intro-9c1.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-intro-9c1.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-intro-9c1.json")],
  "8dc91452": [() => import(/* webpackChunkName: "8dc91452" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-websocket-84a.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-websocket-84a.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-websocket-84a.json")],
  "8e1cf287": [() => import(/* webpackChunkName: "8e1cf287" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-earning-a2f.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-earning-a2f.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-earning-a2f.json")],
  "9f4ac88e": [() => import(/* webpackChunkName: "9f4ac88e" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-example-1f6.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-example-1f6.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-example-1f6.json")],
  "a1907af4": [() => import(/* webpackChunkName: "a1907af4" */ "@site/docs/core-concepts/authorization-authentication/index.md"), "@site/docs/core-concepts/authorization-authentication/index.md", require.resolveWeak("@site/docs/core-concepts/authorization-authentication/index.md")],
  "a209294d": [() => import(/* webpackChunkName: "a209294d" */ "@site/docs/core-concepts/copytrading/index.md"), "@site/docs/core-concepts/copytrading/index.md", require.resolveWeak("@site/docs/core-concepts/copytrading/index.md")],
  "a374f953": [() => import(/* webpackChunkName: "a374f953" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-category-code-examples-58f.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-category-code-examples-58f.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-category-code-examples-58f.json")],
  "a4e83672": [() => import(/* webpackChunkName: "a4e83672" */ "@site/docs/code-examples/javascript/proposal/index.mdx"), "@site/docs/code-examples/javascript/proposal/index.mdx", require.resolveWeak("@site/docs/code-examples/javascript/proposal/index.mdx")],
  "a5581233": [() => import(/* webpackChunkName: "a5581233" */ "@site/docs/code-examples/javascript/ticks/index.mdx"), "@site/docs/code-examples/javascript/ticks/index.mdx", require.resolveWeak("@site/docs/code-examples/javascript/ticks/index.mdx")],
  "a5e58f92": [() => import(/* webpackChunkName: "a5e58f92" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-register-e8e.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-register-e8e.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-register-e8e.json")],
  "a6d7953b": [() => import(/* webpackChunkName: "a6d7953b" */ "@site/docs/core-concepts/websocket/index.md"), "@site/docs/core-concepts/websocket/index.md", require.resolveWeak("@site/docs/core-concepts/websocket/index.md")],
  "a7456010": [() => import(/* webpackChunkName: "a7456010" */ "@generated/docusaurus-plugin-content-pages/default/__plugin.json"), "@generated/docusaurus-plugin-content-pages/default/__plugin.json", require.resolveWeak("@generated/docusaurus-plugin-content-pages/default/__plugin.json")],
  "a7bd4aaa": [() => import(/* webpackChunkName: "a7bd4aaa" */ "@theme/DocVersionRoot"), "@theme/DocVersionRoot", require.resolveWeak("@theme/DocVersionRoot")],
  "a91cd73a": [() => import(/* webpackChunkName: "a91cd73a" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-authentication-517.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-authentication-517.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-authentication-517.json")],
  "a94703ab": [() => import(/* webpackChunkName: "a94703ab" */ "@theme/DocRoot"), "@theme/DocRoot", require.resolveWeak("@theme/DocRoot")],
  "aba21aa0": [() => import(/* webpackChunkName: "aba21aa0" */ "@generated/docusaurus-plugin-content-docs/default/__plugin.json"), "@generated/docusaurus-plugin-content-docs/default/__plugin.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/__plugin.json")],
  "b0888d24": [() => import(/* webpackChunkName: "b0888d24" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-category-languages-65b.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-category-languages-65b.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-category-languages-65b.json")],
  "b301d178": [() => import(/* webpackChunkName: "b301d178" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-alive-b32.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-alive-b32.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-alive-b32.json")],
  "b837beb8": [() => import(/* webpackChunkName: "b837beb8" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-category-javascript-519.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-category-javascript-519.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-category-javascript-519.json")],
  "baaaa0ec": [() => import(/* webpackChunkName: "baaaa0ec" */ "@site/docs/code-examples/javascript/active-symbols/index.mdx"), "@site/docs/code-examples/javascript/active-symbols/index.mdx", require.resolveWeak("@site/docs/code-examples/javascript/active-symbols/index.mdx")],
  "c10c65f7": [() => import(/* webpackChunkName: "c10c65f7" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-ticks-dca.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-ticks-dca.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-ticks-dca.json")],
  "c295bdfe": [() => import(/* webpackChunkName: "c295bdfe" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-javascript-ad9.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-javascript-ad9.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-javascript-ad9.json")],
  "c77c647d": [() => import(/* webpackChunkName: "c77c647d" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-symbols-976.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-symbols-976.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-symbols-976.json")],
  "d37ca184": [() => import(/* webpackChunkName: "d37ca184" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-category-core-concepts-884.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-category-core-concepts-884.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-category-core-concepts-884.json")],
  "d3ff5f78": [() => import(/* webpackChunkName: "d3ff5f78" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-anatomy-992.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-anatomy-992.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-anatomy-992.json")],
  "d425e526": [() => import(/* webpackChunkName: "d425e526" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-concept-afd.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-concept-afd.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-concept-afd.json")],
  "d516add3": [() => import(/* webpackChunkName: "d516add3" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-symbol-47d.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-symbol-47d.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-symbol-47d.json")],
  "d77da98d": [() => import(/* webpackChunkName: "d77da98d" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-authorisation-9a8.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-authorisation-9a8.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-authorisation-9a8.json")],
  "dec0753e": [() => import(/* webpackChunkName: "dec0753e" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-commission-541.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-commission-541.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-commission-541.json")],
  "df203c0f": [() => import(/* webpackChunkName: "df203c0f" */ "@theme/DocTagDocListPage"), "@theme/DocTagDocListPage", require.resolveWeak("@theme/DocTagDocListPage")],
  "f03a4f0a": [() => import(/* webpackChunkName: "f03a4f0a" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-category-javascript-examples-87f.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-category-javascript-examples-87f.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-category-javascript-examples-87f.json")],
  "f08caec6": [() => import(/* webpackChunkName: "f08caec6" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-code-1b9.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-code-1b9.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-code-1b9.json")],
  "f2086140": [() => import(/* webpackChunkName: "f2086140" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-country-list-78e.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-country-list-78e.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-country-list-78e.json")],
  "f2dae80d": [() => import(/* webpackChunkName: "f2dae80d" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-category-guides-350.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-category-guides-350.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-category-guides-350.json")],
  "f3c74015": [() => import(/* webpackChunkName: "f3c74015" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-api-123.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-api-123.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-api-123.json")],
  "fa163b7d": [() => import(/* webpackChunkName: "fa163b7d" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-quick-startup-cdd.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-quick-startup-cdd.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-quick-startup-cdd.json")],
  "fa49948e": [() => import(/* webpackChunkName: "fa49948e" */ "@site/src/pages/api-explorer.tsx"), "@site/src/pages/api-explorer.tsx", require.resolveWeak("@site/src/pages/api-explorer.tsx")],
  "fe4b23de": [() => import(/* webpackChunkName: "fe4b23de" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-authorise-a95.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-authorise-a95.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-authorise-a95.json")],};
