/*
 * AUTOGENERATED - DON'T EDIT
 * Your edits in this file will be overwritten in the next build!
 * Modify the docusaurus.config.js file at your site's root instead.
 */
export default {
  "title": "Deriv API",
  "tagline": "Create your own apps",
  "url": "https://api.deriv.com",
  "baseUrl": "/",
  "onBrokenLinks": "throw",
  "onBrokenMarkdownLinks": "warn",
  "favicon": "img/favicon.png",
  "organizationName": "deriv-com",
  "projectName": "deriv-api-docs",
  "customFields": {
    "trackJsToken": "346262e7ffef497d85874322fff3bbf8",
    "rudderstackKey": "1oV5agvlcnCZ6IH94wCKM1oR8Pd",
    "growthbookClientKey": "sdk-GZvPua1ZAMEL7sT1"
  },
  "i18n": {
    "defaultLocale": "en",
    "locales": [
      "en",
      "fr"
    ],
    "localeConfigs": {
      "en": {
        "label": "English",
        "direction": "ltr"
      }
    },
    "path": "i18n"
  },
  "plugins": [
    "@docusaurus/theme-live-codeblock",
    "docusaurus-plugin-sass",
    [
      "@docusaurus/plugin-client-redirects",
      {
        "redirects": [
          {
            "to": "/docs/intro",
            "from": "/docs"
          }
        ]
      }
    ],
    "/home/runner/work/deriv-api-docs/deriv-api-docs/plugins/custom-webpack-plugin.js"
  ],
  "presets": [
    [
      "classic",
      {
        "docs": {
          "sidebarPath": "/home/runner/work/deriv-api-docs/deriv-api-docs/sidebars.js",
          "showLastUpdateAuthor": false,
          "showLastUpdateTime": true,
          "breadcrumbs": false
        },
        "blog": false,
        "theme": {
          "customCss": [
            "/home/runner/work/deriv-api-docs/deriv-api-docs/src/styles/index.scss"
          ]
        },
        "googleTagManager": {
          "containerId": "GTM-NF7884S"
        }
      }
    ]
  ],
  "themes": [
    [
      "/home/runner/work/deriv-api-docs/deriv-api-docs/node_modules/@easyops-cn/docusaurus-search-local/dist/server/server/index.js",
      {
        "hashed": true
      }
    ]
  ],
  "themeConfig": {
    "navbar": {
      "logo": {
        "alt": "Deriv API logo",
        "src": "img/derivlogo.svg"
      },
      "items": [
        {
          "to": "api-explorer",
          "position": "left",
          "label": "API explorer"
        },
        {
          "to": "https://developers.deriv.com",
          "label": "Documentation",
          "position": "left",
          "className": "external-nav-link"
        },
        {
          "to": "https://tech.deriv.com/",
          "label": "Deriv tech",
          "position": "left",
          "className": "external-nav-link"
        },
        {
          "to": "https://hackerone.com/deriv?type=team",
          "label": "Bug bounty",
          "position": "left",
          "className": "external-nav-link"
        },
        {
          "type": "custom-user-navbar-item",
          "position": "right"
        },
        {
          "type": "localeDropdown",
          "position": "right",
          "dropdownItemsBefore": [],
          "dropdownItemsAfter": []
        }
      ],
      "hideOnScroll": false
    },
    "prism": {
      "theme": {
        "plain": {
          "color": "#403f53",
          "backgroundColor": "#FBFBFB"
        },
        "styles": [
          {
            "types": [
              "changed"
            ],
            "style": {
              "color": "rgb(162, 191, 252)",
              "fontStyle": "italic"
            }
          },
          {
            "types": [
              "deleted"
            ],
            "style": {
              "color": "rgba(239, 83, 80, 0.56)",
              "fontStyle": "italic"
            }
          },
          {
            "types": [
              "inserted",
              "attr-name"
            ],
            "style": {
              "color": "rgb(72, 118, 214)",
              "fontStyle": "italic"
            }
          },
          {
            "types": [
              "comment"
            ],
            "style": {
              "color": "rgb(152, 159, 177)",
              "fontStyle": "italic"
            }
          },
          {
            "types": [
              "string",
              "builtin",
              "char",
              "constant",
              "url"
            ],
            "style": {
              "color": "rgb(72, 118, 214)"
            }
          },
          {
            "types": [
              "variable"
            ],
            "style": {
              "color": "rgb(201, 103, 101)"
            }
          },
          {
            "types": [
              "number"
            ],
            "style": {
              "color": "rgb(170, 9, 130)"
            }
          },
          {
            "types": [
              "punctuation"
            ],
            "style": {
              "color": "rgb(153, 76, 195)"
            }
          },
          {
            "types": [
              "function",
              "selector",
              "doctype"
            ],
            "style": {
              "color": "rgb(153, 76, 195)",
              "fontStyle": "italic"
            }
          },
          {
            "types": [
              "class-name"
            ],
            "style": {
              "color": "rgb(17, 17, 17)"
            }
          },
          {
            "types": [
              "tag"
            ],
            "style": {
              "color": "rgb(153, 76, 195)"
            }
          },
          {
            "types": [
              "operator",
              "property",
              "keyword",
              "namespace"
            ],
            "style": {
              "color": "rgb(12, 150, 155)"
            }
          },
          {
            "types": [
              "boolean"
            ],
            "style": {
              "color": "rgb(188, 84, 84)"
            }
          }
        ]
      },
      "darkTheme": {
        "plain": {
          "color": "#9CDCFE",
          "backgroundColor": "#1E1E1E"
        },
        "styles": [
          {
            "types": [
              "prolog"
            ],
            "style": {
              "color": "rgb(0, 0, 128)"
            }
          },
          {
            "types": [
              "comment"
            ],
            "style": {
              "color": "rgb(106, 153, 85)"
            }
          },
          {
            "types": [
              "builtin",
              "changed",
              "keyword",
              "interpolation-punctuation"
            ],
            "style": {
              "color": "rgb(86, 156, 214)"
            }
          },
          {
            "types": [
              "number",
              "inserted"
            ],
            "style": {
              "color": "rgb(181, 206, 168)"
            }
          },
          {
            "types": [
              "constant"
            ],
            "style": {
              "color": "rgb(100, 102, 149)"
            }
          },
          {
            "types": [
              "attr-name",
              "variable"
            ],
            "style": {
              "color": "rgb(156, 220, 254)"
            }
          },
          {
            "types": [
              "deleted",
              "string",
              "attr-value",
              "template-punctuation"
            ],
            "style": {
              "color": "rgb(206, 145, 120)"
            }
          },
          {
            "types": [
              "selector"
            ],
            "style": {
              "color": "rgb(215, 186, 125)"
            }
          },
          {
            "types": [
              "tag"
            ],
            "style": {
              "color": "rgb(78, 201, 176)"
            }
          },
          {
            "types": [
              "tag"
            ],
            "languages": [
              "markup"
            ],
            "style": {
              "color": "rgb(86, 156, 214)"
            }
          },
          {
            "types": [
              "punctuation",
              "operator"
            ],
            "style": {
              "color": "rgb(212, 212, 212)"
            }
          },
          {
            "types": [
              "punctuation"
            ],
            "languages": [
              "markup"
            ],
            "style": {
              "color": "#808080"
            }
          },
          {
            "types": [
              "function"
            ],
            "style": {
              "color": "rgb(220, 220, 170)"
            }
          },
          {
            "types": [
              "class-name"
            ],
            "style": {
              "color": "rgb(78, 201, 176)"
            }
          },
          {
            "types": [
              "char"
            ],
            "style": {
              "color": "rgb(209, 105, 105)"
            }
          }
        ]
      },
      "additionalLanguages": [
        "bash",
        "diff",
        "json"
      ],
      "magicComments": [
        {
          "className": "theme-code-block-highlighted-line",
          "line": "highlight-next-line",
          "block": {
            "start": "highlight-start",
            "end": "highlight-end"
          }
        }
      ]
    },
    "liveCodeBlock": {
      "playgroundPosition": "bottom"
    },
    "docs": {
      "sidebar": {
        "hideable": true,
        "autoCollapseCategories": true
      },
      "versionPersistence": "localStorage"
    },
    "colorMode": {
      "defaultMode": "light",
      "disableSwitch": true,
      "respectPrefersColorScheme": false
    },
    "metadata": [],
    "tableOfContents": {
      "minHeadingLevel": 2,
      "maxHeadingLevel": 3
    }
  },
  "baseUrlIssueBanner": true,
  "onBrokenAnchors": "warn",
  "onDuplicateRoutes": "warn",
  "staticDirectories": [
    "static"
  ],
  "scripts": [],
  "headTags": [],
  "stylesheets": [],
  "clientModules": [],
  "titleDelimiter": "|",
  "noIndex": false,
  "markdown": {
    "format": "mdx",
    "mermaid": false,
    "mdx1Compat": {
      "comments": true,
      "admonitions": true,
      "headingIds": true
    },
    "anchors": {
      "maintainCase": false
    }
  }
};
